import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
    
  //   if (password !== confirmPassword) {
  //     setError("Passwords don't match");
  //     return;
  //   }
    
  //   try {
  //     const response = await fetch(`http://localhost:8085/api/reset-password/${token}`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ newPassword: password }),
  //     });
      
  //     if (response.ok) {
  //       setSuccess(true);
  //       setTimeout(() => {
  //         navigate('/login');
  //       }, 3000);
  //     } else {
  //       const data = await response.text();
  //       setError(data);
  //     }
  //   } catch (err) {
  //     setError('An error occurred. Please try again.');
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post("http://localhost:8085/reset-password", {
        username: email, // User's email
        newPassword: password, // New password entered by the user
        resetToken: token, // Optional if implemented server-side
      });
  
      if (response.status === 200) {
        alert("Password has been reset successfully.");
      }
    } catch (error) {
      alert("Failed to reset password. Please try again.");
    }
  };
  
  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Reset Password</h2>
      
      {success ? (
        <div className="bg-green-100 p-4 rounded mb-4">
          Password successfully reset! Redirecting to login...
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          {error && (
            <div className="bg-red-100 p-4 rounded mb-4">
              {error}
            </div>
          )}
          
          <div className="mb-4">
            <label className="block mb-2">New Password</label>
            <input
              type="password"
              className="w-full p-2 border rounded"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength={6}
            />
          </div>
          
          <div className="mb-6">
            <label className="block mb-2">Confirm Password</label>
            <input
              type="password"
              className="w-full p-2 border rounded"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              minLength={6}
            />
          </div>
          
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          >
            Reset Password
          </button>
        </form>
      )}
    </div>
  );
};

export default ResetPassword;