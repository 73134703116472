// import React, { useState } from "react";
// import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
// import "../Login/Login.css";
// import { MdDriveFileRenameOutline } from "react-icons/md";
// import { RiLockPasswordFill } from "react-icons/ri";

// const Login = ({ onLogin }) => {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post("/login", { username, password });
//       if (
//         (username === "SttsAdmin" && password === "Stts@2024") ||
//         (username === "AdminStts" && password === "Stts@2024")
//       ) {
//         if (response.status === 200) {
//           onLogin(true);
//           alert("Login successful");
//           navigate("/dashboard");
//         }
//       } else {
//         alert("Invalid credentials");
//       }
//     } catch (error) {

//       alert("Invalid credentials");
//     }
//   };

//   return (
//     <div className="container h-100">
//       <div
//         className="row h-100 justify-content-center align-items-center"
//         style={{ marginTop: "20px", marginBottom: "20px" }}
//       >
//         <form className="col-md-9" onSubmit={handleSubmit}>
//           <div className="AppForm shadow-lg">
//             <div className="row">
//               <div className="col-md-6 d-flex justify-content-center align-items-center">
//                 <div className="AppFormLeft">
//                   <h1>Login</h1>
//                   <div className="form-group position-relative mb-4">
//                     <input
//                       type="text"
//                       value={username}
//                       onChange={(e) => setUsername(e.target.value)}
//                       className="form-control border-top-0 border-right-0 border-left-0 rounded-0 shadow-none"
//                       id="username"
//                       placeholder="Username"
//                       style={{ color: "black" ,padding:"11px"}}
//                     />
//                     <i>
//                       <MdDriveFileRenameOutline />
//                     </i>
//                   </div>
//                   <div className="form-group position-relative mb-4">
//                     <input
//                       type="password"
//                       value={password}
//                       onChange={(e) => setPassword(e.target.value)}
//                       className="form-control border-top-0 border-right-0 border-left-0 rounded-0 shadow-none"
//                       id="password"
//                       placeholder="Password"
//                       style={{ color: "black" }}
//                     />
//                     <i>
//                       <RiLockPasswordFill />
//                     </i>
//                   </div>
//                   <div className="row mt-4 mb-4">
//                     <div className="col-md-6">
//                       <div className="form-check">
//                         <input
//                           className="form-check-input"
//                           type="checkbox"
//                           value=""
//                           id="defaultCheck1"
//                         />
//                         <label
//                           className="form-check-label"
//                           htmlFor="defaultCheck1"
//                         >
//                           Remember me
//                         </label>
//                       </div>
//                     </div>
//                     <div className="col-md-6 text-right">
//                       <a href="#">Forgot Password?</a>
//                     </div>
//                   </div>
//                   <button
//                     type="submit"
//                     className="btn btn-success btn-block shadow border-0 py-2 text-uppercase"
//                   >
//                     Login
//                   </button>
//                   <Link to="/register" className="text-center mt-5">
//                     Don't have an account? <span>Create your account</span>
//                   </Link>
//                 </div>
//               </div>
//               <div className="col-md-6">
//                 <div className="AppFormRight position-relative d-flex justify-content-center flex-column align-items-center text-center p-5 text-white">
//                   <h2 className="position-relative px-4 pb-3 mb-4">Welcome</h2>
//                   <p>
//                     Sign in to unlock exclusive content and manage your account.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Login;

// Login.js
import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../Login/Login.css";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";

// ForgotPasswordModal Component


const ForgotPasswordModal = ({ show, onClose }) => {
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  

  const handleSendCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post("/forgot-password", {
        username: username
      });
      setStep(2);
      setMessage("Verification code sent to your email");
      setError('');
    } catch (err) {
      setError(err.response?.data || "Failed to send verification code");
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      setError("Passwords doesn't match");
      return;
    }

    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }

    setIsLoading(true);
    try {
      await axios.post("/reset-password", {
        username,
        verificationCode,
        newPassword
      });
      
      setMessage("Password reset successful!");
      setTimeout(() => {
        onClose();
        // Reset all states
        setStep(1);
        setUsername('');
        setVerificationCode('');
        setNewPassword('');
        setConfirmPassword('');
        setMessage('');
        setError('');
      }, 2000);
    } catch (err) {
      setError(err.response?.data || "Failed to reset password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (!show) return null;

  return (
    <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)",color:'black'}}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {step === 1 ? "Forgot Password" : "Reset Password"}
            </h5>
            <button 
              type="button" 
              className="btn-close" 
              onClick={onClose}
              aria-label="Close"
            />
          </div>

          <div className="modal-body">
            {message && (
              <div className="alert alert-success">{message}</div>
            )}
            
            {error && (
              <div className="alert alert-danger">{error}</div>
            )}

            {step === 1 ? (
              <form onSubmit={handleSendCode}>
                <div className="mb-3">
                  <label className="form-label">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    disabled={isLoading}
                    style={{color:'black',border:'1px solid black'}}
                  />
                </div>
                <div className="modal-footer">
                  
                  <button 
                    type="submit" 
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Sending...' : 'Send Verification Code'}
                  </button>
                  <button 
                    type="button" 
                    className="btn btn-secondary" 
                    onClick={onClose}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            ) : (
              <form onSubmit={handleResetPassword}>
                <div className="mb-3">
                  <label className="form-label">Verification Code</label>
                  <input
                    type="text"
                    className="form-control"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    required
                    disabled={isLoading}
                    style={{color:'black',border:'1px solid black'}}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    minLength={6}
                    disabled={isLoading}
                    style={{color:'black',border:'1px solid black'}}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Confirm Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    minLength={6}
                    disabled={isLoading}
                    style={{color:'black',border:'1px solid black'}}
                  />
                </div>

                <div className="modal-footer">
                  
                  <button 
                    type="submit" 
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Resetting...' : 'Reset Password'}
                  </button>
                  <button 
                    type="button" 
                    className="btn btn-secondary" 
                    onClick={onClose}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Main Login Component
const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const navigate = useNavigate();
  const decodeJWT = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post("/login", {
        username,
        password,
      });
      console.log("Server Response:", response.data);
  
      const { token, role } = response.data;
         const decodedToken = decodeJWT(token);
      if (decodedToken) {
        
        const role = decodedToken.role;
        // Save auth data
   
        localStorage.setItem("role", role);
      localStorage.setItem("authToken", token);
      localStorage.setItem("role", role);
      console.log("role",role)
  
      if (role === "admin") {
        onLogin(true);
        alert("Login successful");
        navigate("/dashboard");
      } else {
        alert("Login successful");
        navigate("/");
      }
    }
    } catch (error) {
      console.error("Login Error:", error);
      alert("Invalid credentials");
    }
  };
  

  return (
    <div className="container h-100">
      <div
        className="row h-100 justify-content-center align-items-center"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <form className="col-md-9" onSubmit={handleSubmit}>
          <div className="AppForm shadow-lg">
            <div className="row">
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                <div className="AppFormLeft">
                  <h1>Login</h1>
                  <div className="form-group position-relative mb-4">
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="form-control border-top-0 border-right-0 border-left-0 rounded-0 shadow-none"
                      id="username"
                      placeholder="Email"
                      style={{ color: "black", padding: "11px" }}
                    />
                    <i>
                      <MdDriveFileRenameOutline />
                    </i>
                  </div>
                  <div className="form-group position-relative mb-4">
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control border-top-0 border-right-0 border-left-0 rounded-0 shadow-none"
                      id="password"
                      placeholder="Password"
                      style={{ color: "black" }}
                    />
                    <i>
                      <RiLockPasswordFill />
                    </i>
                  </div>
                  <div className="row mt-4 mb-4">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 text-right">
                      <button
                        type="button"
                        className="btn btn-link p-0"
                        onClick={() => setShowForgotPasswordModal(true)}
                      >
                        Forgot Password?
                      </button>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success btn-block shadow border-0 py-2 text-uppercase"
                  >
                    Login
                  </button>
                  <Link to="/register" className="text-center mt-5 d-block">
                    Don't have an account? <span>Create your account</span>
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="AppFormRight position-relative d-flex justify-content-center flex-column align-items-center text-center p-5 text-white">
                  <h2 className="position-relative px-4 pb-3 mb-4">Welcome</h2>
                  <p>
                    Sign in to unlock exclusive content and manage your account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* Forgot Password Modal */}
      <ForgotPasswordModal 
        show={showForgotPasswordModal}
        onClose={() => setShowForgotPasswordModal(false)}
      />
    </div>
  );
};

export default Login;

